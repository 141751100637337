<template>
  <div class="grey lighten-3">
    <div class="simple-header pa-8 text-center white--text">
      <h1>لیست دوره ها</h1>
    </div>
    <v-row class="pa-2 ma-0">
      <v-col cols="12" md="3">
        <div class="rounded-xl pa-4 white mb-3 moholand-shadow">
          <h3>فیلتر کن:</h3>
          <v-row no-gutters>
            <v-col
              cols="12"
              class="mt-2"
              v-for="(category, i) in categories"
              :key="'category' + i"
            >
              <v-btn
                block
                class="rounded-lg"
                :color="selectedLevel == category.level ? 'success' : 'grey'"
                large
                dark
                @click="onSelectCategory(category)"
              >
                <v-icon size="30">{{ category.icon }}</v-icon>
                {{ category.title }}
              </v-btn>
            </v-col>
          </v-row>
        </div>
      </v-col>
      <v-col cols="12" md="9" class="pa-4">
        <v-container fluid class="pt-0">
          <div class="d-flex rounded-lg white align-center moholand-shadow">
            <v-text-field
              v-model="name"
              flat
              solo
              label="دنبال چی میگردی؟"
              hide-details
              class="rounded-r-lg"
            />
            <v-btn
              elevation="0"
              fab
              class="rounded-l-lg rounded-0"
              color="orange darken-1"
              dark
              @click="onSubmit"
            >
              <v-icon>mdi-magnify</v-icon>
            </v-btn>
          </div>

          <small class="mt-4" v-if="total">
            مشاهده صفحه
            {{ current_page }}
            از
            {{ total }}
          </small>

          <v-row class="mt-4" v-if="loading">
            <v-col
              md="4"
              sm="6"
              cols="12"
              v-for="(product, i) in 6"
              :key="'skeleton' + i"
            >
              <v-skeleton-loader
                type="card, card-heading"
                class="moholand-shadow"
              />
            </v-col>
          </v-row>

          <v-row class="mt-4" v-else>
            <v-col
              md="4"
              sm="6"
              cols="12"
              v-for="(item, i) in items"
              :key="'item' + i"
            >
              <course-item :item="item" />
              <!-- <ProductBox
                :name="product.name"
                :url="product.url"
                :img="product.media.url"
                :price="
                  product.offer_price ? product.offer_price : product.price
                "
                :old-price="product.offer_price && product.price"
              /> -->
            </v-col>
          </v-row>
          <my-paginate
            v-model="current_page"
            :value="current_page"
            :length="pagesLength"
          />
          <h4 class="text-center mt-15 grey--text" v-if="!total && !loading">
            هیچ فایلی مطابق با جستجوی شما یافت نشد!
          </h4>
        </v-container>
      </v-col>
    </v-row>
  </div>
</template>

<script>
import MyPaginate from "../../components/MyPaginate.vue";
import CartButton from "../../components/Pages/CartButton.vue";
import MomentsOffer from "../../components/Pages/MomentsOffer.vue";
import ProductBox from "../../components/Pages/ProductBox.vue";
import MyAxios from "@/constants/MyAxios";
import CourseItem from "../../components/CourseItem.vue";

export default {
  components: { MyPaginate, ProductBox, CartButton, MomentsOffer, CourseItem },
  data() {
    return {
      name: this.$route.query.name || "",
      sortBySelected: null,
      items: [],
      pagesLength: 1,
      path: null,
      total: null,
      current_page: 1,
      loading: true,
      courses: [],
      selectedLevel: this.$route.query.level || undefined,
      categories: [
        {
          level: 1,
          title: "مقدماتی",
        },
        {
          level: 2,
          title: "پیشرفته",
        },
        {
          level: 3,
          title: "حرفه ای",
        },
      ],
    };
  },
  methods: {
    // getCategories() {
    //   MyAxios.get("/categories/index", {
    //     params: {
    //       noPaginate: true,
    //       conditions: {
    //         parent_id: 15,
    //       },
    //     },
    //   })
    //     .then((response) => {
    //       this.categories = response.data;
    //     })
    //     .catch((error) => {
    //       console.log("laws", error.response);
    //     });
    // },
    getData() {
      this.loading = true;
      console.log("level", this.selectedLevel);
      MyAxios.get("/courses/search", {
        params: {
          conditions: {
            title: this.name || undefined,
            level: this.selectedLevel || undefined,
            status: 1,
          },
          page: this.current_page,
        },
      })
        .then((response) => {
          this.items = response.data?.data || [];
          this.pagesLength = response.data.last_page;
          this.path = response.data.path;
          this.total = response.data.total;
          this.current_page = response.data.current_page;
          console.log("response.data", response.data);
          this.loading = false;
        })
        .catch((error) => {
          this.loading = false;
        });
    },
    onSubmit() {
      this.$router.push({
        name: "Courses",
        query: {
          name: this.name,
        },
      });
      this.getData();
    },
    onSelectCategory(item) {
      if (this.selectedLevel) {
        this.selectedLevel = undefined;
        this.$router.push({
          name: "Courses",
          query: {
            name: this.name || undefined,
            level: undefined,
          },
        });
      } else {
        this.selectedLevel = item.level;
        this.$router.push({
          name: "Courses",
          query: {
            name: this.name || undefined,
            level: this.selectedLevel || undefined,
          },
        });
      }
      this.getData();
    },
  },
  created() {
    this.getData();
    // this.getCourses();
    this.getCategories();
  },
};
</script>

<style scoped>
.simple-header {
  width: 100%;
  background: url("../../assets/header.jpg");
}
</style>
